#guest-portal .form-label {
  padding: 0px;
  color: var(--secondary-font-color) !important;
  padding-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

#guest-portal .editor-control {
  background-color: var(--text-input-bg-color);
  border: 1px solid var(--text-input-border-color);
  color: var(--primary-font-color);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 9px 13px;
  font-family: "Inter" !important;
}

#guest-portal .form-select {
  background-color: var(--text-input-bg-color);
  border: none;
  color: var(--primary-font-color);
  ::placeholder {
    color: var(--secondary-font-color);
  }
}

#guest-portal .editor-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-font-color) !important;
  font-family: "inter";
}

.guest-modal-footer {
  padding: 22px 32px;
  border: none;
  background: var(--main-background-color);
  display: flex;
  justify-content: center;
}

#guest-portal .save-button {
  background: var(--primary-button-color) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;

  border: none !important;
  padding: 9px 17px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--primary-button-font-color) !important;
  font-family: "Inter" !important;
}

#guest-portal .save-button:hover {
  background-color: var(--primary-button-hover-color) !important;
}

#guest-portal .save-button:active {
  background-color: var(--primary-button-active-color) !important;
}

#guest-portal .cancel-button {
  background: var(--secondary-button-color) !important;
  border: 1px solid var(--primary-button-border-color) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;

  padding: 9px 17px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: var(--secondary-button-font-color) !important;
  line-height: 22px !important;
}

#guest-portal .cancel-button:hover {
  background-color: var(--secondary-button-hover-color) !important;
}

#guest-portal .cancel-button:active {
  background-color: var(--secondary-button-active-color) !important;
}

#guest-portal .form-control {
  background-color: var(--text-input-bg-color) !important;
  color: var(--primary-font-color) !important;
}

#guest-portal .form-control:disabled {
  background-color: #1f1e1e !important;
  opacity: 1 !important;
}

footer {
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 0;
}

.signature-canvas {
  width: 100%;
  height: 200px;
}

.signature-canvas-container {
  width: 100%;
  height: 200px;
  background-color: white;
  border: 0.5px solid #515151 !important;
}

.card-button {
  display: flex;
  border-radius: 100%;
  background-color: var(--secondary-button-color);
  color: black;
  align-items: center;
  justify-content: center;
  border-color: black;
  width: 25px;
  height: 25px;
  padding: 0px;
}

.checkout-inputs {
  display: block;
  padding: 9px 13px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--primary-font-color) !important;
  background-color: var(--text-input-bg-color);
  background-clip: padding-box;
  appearance: none;
  border: 0.5px solid #515151 !important;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-style: normal;
  font-family: "Inter" !important;
  width: 100%;
  height: 37px;
}

.no-border-radius {
  border-radius: 0px !important;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--secondary-font-color);
}

.guest-modal-header {
  background-color: var(--main-background-color);
}
.guest-modal-body {
  background-color: var(--main-background-color);
  margin-bottom: 10px;
}

.guest-inputs {
  border: 1px solid #515151 !important;
  border-radius: 0;
}

.background {
  background-color: black !important;
}

.datePicker {
  width: 100% !important;
}

.error-text {
  color: #ff7c7c;
  font-size: 14px !important;
}
.password-error-text {
  color: #ff7c7c;
  font-size: 12px !important;
}

.corousselImages {
  width: 100%;
  height: 350px;
}

/* Change background color of the calendar */
.react-datepicker {
  background-color: #0f0f0f !important;
  border: 1px solid #515151 !important;
}

/* Change font color of the calendar */
.react-datepicker__day-name,
.react-datepicker__day {
  color: #f8f8f8 !important;
}

/* Change background color of the selected date */
.react-datepicker__day--selected {
  background-color: #007bff;
  color: #ffffff; /* Change text color to ensure visibility */
}

/* Change color of the current month and year displayed in the calendar */
.react-datepicker__current-month,
.react-datepicker__current-year {
  color: #f9f7f5 !important;
}

/* Change hover effect of the calendar */
.react-datepicker__day:hover {
  background-color: #a4a4a4 !important;
}

.react-datepicker__header {
  background-color: #0f0f0f !important; /* Blue color */
  color: #ffffff !important; /* White text color */
  border-bottom: 1.2px solid #515151 !important;
}

.react-datepicker__navigation {
  color: #ffffff !important;
}

.react-datepicker__navigation:hover {
  color: #cccccc !important;
}

.react-datepicker__year-select {
  border: 1px solid #515151 !important;
  border-radius: 0;
  background-color: black !important;
  color: white;
  margin-top: 10px !important;
  padding: 3px;
}
.react-datepicker__month-select {
  border: 1px solid #515151 !important;
  border-radius: 0;
  background-color: black !important;
  color: white;
  margin-top: 10px !important;
  padding: 3px;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: gray !important;
}
