.reservation-detail-container {
  padding: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 140px);
}

.room-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.room-image {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.room-name-container {
  margin-left: 10px;
}

.room-name {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #222222;
}

.room-details {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  opacity: 0.6;
}

.checkin-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.portal-access-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkin-detail-container {
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.devider {
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  margin: 15px 0px 15px 0px;
}

.verification-step-title {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #222222;
  text-transform: uppercase;
}

.verification-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.verification-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.verification-item-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #222222;
  line-height: 15px;
  margin-left: 5px;
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verification-report {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  color: #222222;
  text-transform: uppercase;
  margin-left: 5px;
}

.download-btn {
  background-color: #3567e6;
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 5px 15px 5px 15px;
  cursor: pointer;
}

.download-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin-left: 6px;
}

.revoke-btn {
  background-color: #db453c;
  flex-direction: row;
  display: flex;
  align-items: center;

  border-radius: 8px;
  padding: 5px 15px 5px 15px;
  cursor: pointer;
}
.copy-reservation-url-btn {
  background-color: #14bf59;

  border-radius: 8px;
  padding: 5px 12px 5px 12px;
  cursor: pointer;
  margin-right: 5px !important;
}

.revoke-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.upsells-container {
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  margin-top: 10px;
}

.status-detail-container {
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-btn {
  background-color: #14bf59;
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 5px 15px 5px 15px;
  margin-right: 10px;
  cursor: pointer;
}

.fee-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.fee-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: #222222;
  margin-right: 10px;
}

.fee-expand-btn {
  cursor: pointer;
}

.info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.fee-text-title {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  opacity: 0.6;
}

.info-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.upsell-status-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.paid-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
}
