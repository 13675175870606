@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("../../../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

#guest-portal .container {
  font-family: "HelveticaNeueCyr" !important;

  font-weight: 500 !important;
}

#guest-portal .table-title h3 {
  font-style: normal;
  font-size: 23px;
  font-weight: 500 !important;
  color: white;
}
#guest-portal .table-title h6 {
  font-style: normal;
  font-size: 14px;
  font-weight: 500 !important;
  color: white;
}

.error-subtitle {
  font-style: normal;
  font-size: 20px;
  font-weight: 500 !important;
  margin-top: 3px;
  color: var(--secondary-font-color);
}

.page-container {
  background-color: var(--main-background-color);
  width: 50%;
  max-width: 780px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2%;
  color: var(--primary-font-color);
}

.page-container.guest-portal {
  background-color: #0f0f0f;
}

.number-circle {
  background-color: transparent;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 100%;
  min-width: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 1px 1px 1px;
  font-size: 22px;
  margin-top: 10px;
}

.terms-privacy-text {
  color: var(--primary-font-color);
  font-size: 14px;
  cursor: pointer;
}

.card-icon {
  color: var(--primary-font-color) !important;
}

.direction-title {
  width: 50%;
  font-size: 14px;
  margin-left: 14px;
}
.network-title {
  color: var(--secondary-font-color);
  font-size: 14px;
}

.password-title {
  color: var(--secondary-font-color);
  font-size: 14px;
}

.password-align {
  display: flex;
  justify-content: right;
  align-items: center;
}
.network-align {
  display: flex;
  justify-content: left;
  align-items: center;
}

.verify-me-button {
  width: 100%;
  height: 5rem;
  border-radius: 0px !important;
}

.card-item {
  background-color: var(--main-background-color) !important;
  display: flex;
  justify-content: "center";
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button {
  background-color: var(--main-background-color);
  color: var(--primary-font-color);
  padding-left: 0px;
  border-bottom: 1px solid #595959;
}

.accordion {
  background-color: #0f0f0f !important;
  --bs-accordion-border-color: var(--main-background-color);
  --bs-accordion-bg: var(--main-background-color);
  --bs-accordion-color: white;
  --bs-accordion-inner-border-radius: 0px;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: var(--main-container-bg-color);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.social-media-title {
  color: var(--secondary-font-color) !important;
  text-align: center;
}

.customize-stay-container {
  height: 150px;
  width: 100%;
  background-color: gray;
  margin-right: 0 !important;
}
.customize-stay-selected-container {
  height: 150px;
  width: 100%;
  background-color: gray;
  margin-right: 0 !important;
  border: 2px solid #2ce983 !important;
}

.scroll-portal {
  min-height: 13rem;
  max-height: 26rem;
  white-space: nowrap;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.notify-badge {
  position: absolute;
  margin-top: 5px;

  background-color: black !important;
  text-align: center;
  border-radius: 30%;
  color: #afafaf;
  padding: 7px 7px;
  font-size: 20px;
  margin-right: 5px;
}
.quantity-badge {
  position: absolute;
  margin-top: 5px;

  background-color: black !important;
  text-align: center;
  border-radius: 30%;
  color: #afafaf;
  padding: 8px 10px;
  font-size: 16px;
  margin-left: 5px;
}

/* Customizing scrollbar */
.scroll-portal::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.scroll-portal::-webkit-scrollbar-track {
  background-color: #f1f1f100; /* Color of the scrollbar track */
}

.scroll-portal::-webkit-scrollbar-thumb {
  background-color: rgba(183, 183, 183, 0.222); /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the scrollbar thumb */
}

.scroll-portal::-webkit-scrollbar-thumb:hover {
  background-color: #55555573; /* Color of the scrollbar thumb on hover */
}

.customize-stay-subtitle {
  margin-top: 8px;
  font-size: 14px;
}

.room-code {
  padding-top: 1px;
  color: black;

  font-weight: bold;
}

.reservation-date {
  padding-top: 1px;
  font-size: 16px;
  line-height: 20px;
}

.package-container {
  cursor: pointer;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.image-container {
  background-image: linear-gradient(
      180deg,
      rgba(15, 15, 15, 0.65) 0%,
      rgba(15, 15, 15, 0.75) 38%,
      rgba(15, 15, 15, 0.8) 47%,
      rgba(15, 15, 15, 0.9) 65%,
      rgba(15, 15, 15, 1) 82%
    ),
    url("../../../../public/room.png");
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 25px;
  padding-right: 25px;
}

.container-paddings {
  padding-left: 25px;
  padding-right: 25px;
}

.direction-padding {
  padding-top: 8px;
}

.message-button-icon {
  color: #ffffff;
}

.message-button-icon:hover {
  color: #2ce983 !important;
  cursor: pointer;
}

.message-button-icon:active {
  color: #1ac363 !important;
  cursor: pointer;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;

  border-radius: 0px !important;
  border: 1px solid #515151 !important;

  height: 40px !important;
  width: 100% !important;
  outline: none;
}

.phone-dropdown-class {
  background-color: black;
}

.react-tel-input .country-list .country {
  padding: 7px 9px;
  background: #0f0f0f;
}
.react-tel-input .country-list .country:hover {
  background: #262626 !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #1a1a1a !important;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  background-color: #0f0f0f !important;
  border-radius: 0px !important;
  border: none !important;
  border-color: #0f0f0f !important;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #ffffff !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none !important;
  border-bottom: 4px solid #ffffff !important;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;

  border: 1px solid #515151 !important;
  border-radius: 3px 0 0 3px;
}

@media screen and (max-width: 992px) {
  .customize-stay-container {
    height: 75px;
    width: 100%;
  }
  .image-container {
    padding-left: 17px;
    padding-right: 17px;
  }
  .direction-padding {
    padding-top: 5px;
  }

  .container-paddings {
    padding-left: 17px;
    padding-right: 17px;
  }

  .table-title {
    padding-left: 0px;
  }
  .page-container {
    width: 95%;
  }
}

@media screen and (max-width: 550px) {
  .customize-stay-container {
    height: 75px;
    width: 100%;
  }
  .page-container {
    padding-right: 0px;
    padding-left: 0px;
    width: 100% !important;
  }
  .direction-padding {
    padding-top: 2px;
  }
  .direction-title {
    width: 70%;
    font-size: 14px;
  }
  .customize-stay-subtitle {
    font-size: 12px;
  }
  .image-container {
    width: 100%;
    height: 11rem;
    padding-left: 15px;
    padding-right: 15px;
  }

  .container-paddings {
    padding-left: 15px;
    padding-right: 15px;
  }
}
