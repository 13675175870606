#header {
  position: relative;
}

.header-view {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px 20px 15px 32px;
  border-bottom: 1px solid #e9e9e9;
}

.header-search {
  margin-right: 20px;
}

.header-search-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-title-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: #363636;
  line-height: 24px;
  margin-left: 15px;
}

.popover {
  --bs-popover-max-width: 500px;
}

.notification-badge {
  background-color: red;
  padding: 2px 5px;
  border-radius: 20px;
  position: absolute;
  top: -6px;
  right: -6px;
  font-family: "Inter";
  font-weight: 800;
  font-size: 9px;
  color: #ffffff;
}

.notification_overlay {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px;
  width: 400px;
  height: 522px;
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-overlay-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 12px;
  border-bottom: 1px solid #e9e9e9;
}

.search-overlay-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: #202020;
  line-height: 24px;
}

.search-overlay-read-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #202020;
  line-height: 20px;
  margin-left: 5px;
}

.notification-item {
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  display: flex;
  cursor: pointer; 
}

.notification_divider {
  height: 1px;
  width: 90%;
  background-color: #F1F1F1;
  margin-left: 5%;
}

.notification-item:hover {
  background-color: #F8F8F8;
}

.notification-icon {
  height: 46px;
  width: 46px;
  border-radius: 23px;
  background-color: #f8f8f8;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.notification-item-badge {
  background-color: #db453c;
  width: 11px;
  height: 11px;
  border-radius: 7px;
  position: absolute;
  top: 2px;
  right: 0px;
  border: 1.7px solid #ffffff;
}

.notification-list-container {
  display: flex;
  flex: 1;
  overflow-y: scroll;
  flex-direction: column;
  max-height: 457px;
}

.text-notification-list {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #202020;
  line-height: 20px;
}

.text-notification-date {
  font-family: "Inter";
  font-weight: 500;
  font-size: 13px;
  color: #808080;
  line-height: 20px;
  margin-top: 7px;
}

.notification-title-container {
  margin-left: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header-back-container {
  flex-direction: row;
  display: flex;
  align-items: center;
}