.sidebar_container {
  width: 275px;
  background: #232323;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: width 0.6s;
  align-items: flex-start;
  padding: 0;
  z-index: 2;
}

.main_container {
  z-index: 2;
  backdrop-filter: blur(4px);
  width: 100%;
  padding: 0;
  flex: 1;
  display: flex;
}

.logo_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  padding-left: 20px;
}

.logo_image {
  width: 24px;
  height: 24px;
}

.logo_text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  margin-left: 8px;
  color: white;
  line-height: 24px;
}

.page_list_container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 350px);
}

.page_list_container::-webkit-scrollbar {
  display: none;
}

.page_item_container {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  padding-right: 30px;
  border-left: 2px solid transparent;
  width: 100%;
}

.page_item_color {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-bottom: 12px;
  align-items: center;
  cursor: pointer;
  padding-right: 30px;
  border-left: 2px solid #15cf5f;
}

.page_icon {
  width: 22px;
  height: 22px;
  margin: 0 8px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: #909090;
  display: flex;
  flex: 1;
  line-height: 22px;
}

.page_name_select {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: #15cf5f;
  display: flex;
  flex: 1;
  line-height: 22px;
}

.property_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #888d9d;
  line-height: 15px;
}

.property__name_text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.property_container {
  width: 90%;
  background-color: #2e2e2e;
  padding: 10px 12px 10px 12px;
  margin: 30px 10px 10px 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.property_details_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
}

.user_divider {
  height: 1px;
  width: 80%;
  background-color: #353535;
  align-self: center;
}

.user_overlay_divider {
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
  margin-top: 10px;
}

.user_container {
  margin-bottom: 30px;
  width: 100%;
}

.user_image {
  width: 35px;
  height: 35px;
  border-radius: 20px;
}

.user_details_container {
  width: 90%;
  padding: 10px 0px 10px 12px;
  margin: 30px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user_details_enclosed {
  width: 100%;
  padding: 10px 0px 10px 12px;
  margin: 30px 10px 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.property_overlay {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px;
}

.search_property_container {
  padding: 15px;
}

.property_list_item {
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 15px 15px 15px 11px;
  border-top: 2px solid #e9e9e9;
  border-left: 4px solid transparent;
  cursor: pointer;
}

.property_list_item:hover {
  background-color: #fcfcfc;
}

.property_list_item_select {
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 15px 15px 15px 11px;
  border-top: 2px solid #e9e9e9;
  border-left: 4px solid #15cf5f;
  cursor: pointer;
}
.property_list_item_select:hover {
  background-color: #fcfcfc;
}

.property_list_title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-left: 10px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-three-dot {
  cursor: pointer;
  padding: 20px 12px 20px 20px;
}

.user_overlay {
  width: 170px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
}

.user_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}

.user_item:hover {
  background-color: #fcfcfc;
}

.user_action {
  margin-left: 10px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
}

.sub_menu_container {
  padding-left: 52px;
  cursor: pointer;
}

.sub_menu_name {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #909090;
  line-height: 18px;
  padding-bottom: 20px;
}
