.icon-check-in-card {
  background-color: #fff !important;
  border: none !important;
  width: 100%;
  height: 5.3rem;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.card-check-text {
  color: black !important;
  margin-top: 15px;
  margin-left: 20px;
  font-size: 14px;
}
.card-text-content {
  color: black !important;
  margin-left: 20px;
}

.card-row {
  height: 100%;
}

.checkin-icon-container {
  display: flex;
  justify-content: center;
  background-color: #2ce983;
  cursor: pointer;
  margin: 0;
  width: 5.5rem;
  height: 100%;
}

.checkin-unactive-container {
  display: flex;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
  margin: 0;
  color: black;
  width: 5.5rem;
  height: 100%;
}

.checkin-icon-container:hover {
  background-color: #65f3a7;
}

.checkin-icon-container:active {
  background-color: #3abb76;
}

.accordion {
  background-color: var(--main-container-bg-color);
  color: white;
}

.no-padding-row {
  --bs-gutter-x: 0rem !important;
}

@media screen and (max-width: 992px) {
  .card-check-text {
    margin-left: 17px;
  }
  .card-text-content {
    margin-left: 17px;
  }
}

@media screen and (max-width: 550px) {
  .card-check-text {
    margin-left: 15px;
  }
  .card-text-content {
    margin-left: 15px;
  }
}
