#inbox .table-title h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #f6f6f6;
}

#inbox .table-title h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #afafaf;
}

#inbox .table-container {
  width: 100%;
  background-color: white;
}

#inbox .reservation-container {
  background-color: #ffffff;
  color: #f6f6f6;
  padding: 0px;
  border-left: 1px solid #e9e9e9;
}

#inbox .reservation-container h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #f6f6f6;
}

#inbox .reservation-container .customer-name {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #2bd579;
}

#inbox .reservation-container .reservation-info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

#inbox .reservation-container .reservation-info h6 {
  font-size: 14px;
}

#inbox .reservation-container .reservation-dates {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #afafaf;
}

#inbox .reservation-container .reservation-info h4 {
  font-size: 14px;
  color: #000000;
}

#inbox .message-timestamp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f6f6f6;
}

#inbox .reservation-container .reservation-dates h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #f6f6f6;
}

#inbox .reservation-container .reservation-dates h6 {
  font-size: 14px;
}

#inbox .sidebar-title {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  height: 60px;
  padding: 0px 10px 0px 10px;
}

#inbox .sidebar-title h3 {
  margin: 0px;
}

.table-message-container {
  min-height: 500px;
  height: calc(100vh - 83px);
  background-color: #ffffff;
  overflow-y: auto;
}

.scrollable-users-list,
.scrollable-messages-list {
  overflow-y: auto;
}

.full-height {
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.message-input-area {
  flex-shrink: 0;
}

.chat-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #afafaf;
}

.chat-header {
  color: #afafaf;
}

.back-button {
  display: none;
}

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid #e2e8f0;
  background-color: #f7fafc;
  transition: background-color 0.2s, color 0.2s;
}

.btn.btn-primary.dropdown-toggle:hover {
  background-color: unset !important;
}

.messages-container {
  background-color: #f3f3f3;
  height: 100%;
}

.user-select-button {
  color: #f6f6f6;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.icon-button:hover {
  background-color: #2bd579 !important;
  border-color: #2bd579 !important;
  color: #ffffff;
}

.icon-button svg {
  width: 1.5rem;
  height: 1.5rem;
}

#inbox .form-control {
  background-color: #232323;
  color: #afafaf;
  border-color: #232323;
}

#inbox .send-message-input {
  background-color: #232323;
  color: #afafaf;
  border-color: #232323;
}

#inbox .send-message-button {
  background-color: #232323;
  color: #afafaf;
  border-color: #232323;
}

#inbox .message-list-container {
  flex: 1 1 auto;
}

.btn-transparent {
  background-color: transparent;
  color: #f6f6f6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e9e9e9 !important;
}

.btn-transparent:hover {
  background-color: #f8fdf8 !important;

  color: #ffffff;
}

.btn-grey {
  background-color: #f8fdf8;
  color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e9e9e9 !important;
}

.btn-grey:hover {
  background-color: #f8fdf8 !important;
  color: #ffffff;
}

.chat-area {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.chat-sidebar {
  width: 20rem;
  padding: 1rem;
  overflow-y: auto;
}

.chat-message {
  padding: 1rem;
  overflow-y: auto;
  height: 96px;
}

.user-list-item {
  display: block;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}

.user-list-item:hover {
  background-color: #2bd579;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  object-fit: cover;
}

.user-details {
  flex-grow: 1;
  min-width: 0;
}

.user-name {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-message {
  font-size: 0.875rem;
  color: #a0aec0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-time {
  font-size: 0.75rem;
  color: #a0aec0;
}

#inbox .message-form {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid #e2e8f0;
}

#inbox .message-input {
  flex-grow: 1;
  height: 2.5rem;
  border-radius: 0.375rem;
  border: 1px solid #232323;
  padding: 0.5rem;
  font-size: 0.875rem;
}

#inbox .conversation-list-container {
  padding: 0;
  border-right: 1px solid #e9e9e9;
}

#inbox .conversation-list {
  padding: 0;
}

#inbox .user-message-lastmessage {
  color: #afafaf;
  font-size: 14px;
}

#inbox .badge-unread-message {
  background-color: #2bd579 !important;
  margin-left: 5px;
  color: #fff;
}

#inbox .message-empty-message {
  color: #afafaf;
  font-size: 16px;
}

#inbox .no-data-list {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.reservation-details {
  padding: 1rem;
  overflow-y: auto;
}

.reservation-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.reservation-info {
  line-height: 1.5;
}

.reservation-info strong {
  font-weight: bold;
}

.message-bubble {
  padding: 10px 15px;
  margin-bottom: 8px;
  max-width: 75%;
  word-wrap: break-word;
  font-size: 16px;
}

.inbound {
  background-color: #ffffff;
  align-self: flex-start;
  border-radius: 20px 20px 20px 0;
}

.outbound {
  background-color: #cbf5c7;
  border-radius: 20px 20px 0 20px;
  align-self: flex-end;
  color: #ffffff;
}

.inbound-corner {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  rotate: 135deg;
  position: absolute;
  bottom: 7px;
  left: -6px;
}

.outboud-corner {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #cbf5c7;
  rotate: 225deg;
  position: absolute;
  bottom: 7px;
  right: -6px;
}

#inbox .messages-title {
  background-color: #ffffff;
  color: #f6f6f6;
  height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}

#inbox .search-user-icon {
  background-color: #232323;
  border: none;
  color: #f6f6f6;
}

#inbox .sidebar-button {
  border: none;
  color: #f6f6f6;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#inbox .booking-details-title {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 8px;
  padding-right: 20px;
}

#inbox .header-chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
}

#inbox .header-chat-title {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000000;
}

.message-channel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-font-color) !important;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#inbox .hide-side-nav {
  transition: width 0.5s ease-in-out;
  width: 0; /* Hide the column */
  overflow: hidden; /* Prevent content overflow when hidden */
}

.message-bubble p {
  margin: 0;
  color: white;
}

.mainView {
  margin: 0;
  padding: 0;
}

.user_image {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 10px 10px 10px 0px;
}

.user-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 5px;
  align-self: center;
}

.user-item {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0px 10px 0px 0px;
}

.user-name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  color: #222222;
  line-height: 17px;
}

.room-id {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 15px;
  margin-top: 5px;
}

.select-border {
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #15cf5f !important;
  margin-right: 7px;
}

.un-select-border {
  width: 3px;
  margin-right: 7px;
}

.user-date {
  display: flex;
  padding: 10px 0px 10px 0px;
  align-items: flex-end;
  flex-direction: column;
}

.bottom-send-container {
  flex-direction: row;
  display: flex;
  padding: 8px 20px 8px 20px;
  background-color: #ffffff;
  border-top: solid #f0f0f0 1px;
}

.send-input-container {
  background-color: #fbfbfb !important;
  border-radius: 0px;
  border: none !important;
  color: #000000 !important;
}

#inbox .bottom-input-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  flex: 1;
  background-color: #fbfbfb;
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
}

.message-title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #21391e;
}

.msg-text {
  display: flex;
  align-self: flex-end;
  font-family: "Inter";
  font-weight: 500;
  font-size: 10px;
  margin-bottom: 10px;
  color: #222222;
}

.emoji-picker {
  border: none;
  width: 500px;
  height: 100px;
}

.upsell-text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: -0.01em;
}

@media screen and (max-width: 992px) {
  .table-title {
    padding-left: 0px;
  }
  .table-container {
    width: 95%;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .chat-sidebar,
  .chat-message,
  .reservation-details {
    width: 100%;
  }

  .chat-area {
    flex-direction: column;
  }

  .icon-button,
  .message-input {
    padding: 0.5rem;
  }

  .chat-title,
  .reservation-title {
    font-size: 1rem;
  }

  .user-name,
  .user-message,
  .message-time {
    font-size: 0.75rem;
  }

  #inbox .message-list-container {
    max-height: 60%;
  }
}
